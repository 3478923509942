<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Table</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Layout
        <a class="close" v-on:click="collapsed['layout'] = !collapsed['layout']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['layout']">
        <div class="type_common_input">
          <span class="label">Apply</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="layoutApply" type="radio" name="layoutApply" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input type_color_float">
          <label>Margin / Background Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="layoutMargin" class="type22" type="number" />
            <j-color-picker v-model="layoutBackColor"></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Border
        <a class="close" v-on:click="collapsed['border'] = !collapsed['border']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['border']">
        <div class="type_common_input">
          <span class="label">Apply</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="borderApply" type="radio" name="borderApply" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <span class="label">Top</span>
        <div class="type_inner">
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="borderTopWeight" class="type22" type="number" />
              <j-color-picker v-model="borderTopColor"></j-color-picker>
            </div>
          </div>
          <div class="type_common_input type_gray">
            <label>Opacity</label>
            <input v-model="borderTopOpacity" class="type22" type="number" />
          </div>
        </div>
        <span class="label">Right</span>
        <div class="type_inner">
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="borderRightWeight" class="type22" type="number" />
              <j-color-picker v-model="borderRightColor"></j-color-picker>
            </div>
          </div>
          <div class="type_common_input type_gray">
            <label>Opacity</label>
            <input v-model="borderRightOpacity" class="type22" type="number" />
          </div>
        </div>
        <span class="label">Bottom</span>
        <div class="type_inner">
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="borderBottomWeight" class="type22" type="number" />
              <j-color-picker v-model="borderBottomColor"></j-color-picker>
            </div>
          </div>
          <div class="type_common_input type_gray">
            <label>Opacity</label>
            <input v-model="borderBottomOpacity" class="type22" type="number" />
          </div>
        </div>
        <span class="label">Left</span>
        <div class="type_inner">
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="borderLeftWeight" class="type22" type="number" />
              <j-color-picker v-model="borderLeftColor"></j-color-picker>
            </div>
          </div>
          <div class="type_common_input type_gray">
            <label>Opacity</label>
            <input v-model="borderLeftOpacity" class="type22" type="number" />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Pagination
        <a class="close" v-on:click="collapsed['pagination'] = !collapsed['pagination']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['pagination']">
        <div class="type_common_input">
          <span class="label">Enabled</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="tablePagingEnabled" name="tablePagingEnabled" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Type</span>
          <div class="row">
            <label v-for="item in typeOptions" :key="item.index" class="type_many">
              <input v-model="tablePageType" name="tablePageType" type="radio" :value="item.value" />
              {{item.text}}
            </label>
          </div>
        </div>
        <!-- <div class="type_common_input">
          <span class="label">Pagination Style</span>
          <div class="row">
            <label v-for="item in pageOptions" :key="item.index" class="type_many">
              <input v-model="tablePageStyle" name="tablePageStyle" type="radio" :value="item.value" />
              {{item.text}}
            </label>
          </div>
        </div> -->
        <div class="type_common_input">
          <span class="label">Page No. of Total</span>
          <div class="row">
            <label v-for="item in yesnoOptions" :key="item.index" class="type_many">
              <input v-model="tablePageNum" name="tablePageNum" type="radio" :value="item.value" />
              {{item.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Rows per Page</span>
          <div class="row">
            <div class="type_common_input">
              <input v-model="tablePageRowNum" type="number" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Display
        <a class="close" v-on:click="collapsed['display'] = !collapsed['display']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['display']">
        <div class="type_common_input">
          <span class="label">Information Box</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="tableInfoBoxDisplay" name="tableInfoBoxDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-if="infoAvailable" class="type_common_input">
          <span class="label">Title</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="tableTitleDisplay" name="tableTitleDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-if="infoAvailable" class="type_common_input">
          <span class="label">Legend</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="tableLegendDisplay" name="tableLegendDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-if="infoAvailable" class="type_common_input">
          <span class="label">Search Input</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="tableSearchDisplay" name="tableSearchDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-if="infoAvailable" class="type_common_input">
          <span class="label">Excel Export</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="tableExcelDisplay" name="tableExcelDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Action Button
        <a class="close" v-on:click="collapsed['button'] = !collapsed['button']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['button']">
        <div class="type_common_input">
          <span class="label">Activate</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="actionButtonActivate" name="actionButtonActivate" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Button Label</label>
          <input v-model="actionButtonLabel" type="text" class="algin_left type130"/>
        </div>
        <div class="type_common_input">
          <label>Component</label>
          <input v-model="actionButtonComponent" type="text" class="algin_left type130"/>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Excel Export Limit
        <a class="close" v-on:click="collapsed['button'] = !collapsed['button']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['button']">
        <div v-if="infoAvailable" class="type_common_input">
          <span class="label">Limited Row</span>
          <div class="row">
            <div class="type_common_input">
              <input v-model="tableExcelLimited" type="number" class="type130"/>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import _Dataform from '@/primitives/_dataformDatatable'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-summary-table---abds',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      layout: true,
      border: true,
      pagination: true,
      display: true,
      button: true,

      header: false,
      cells: false,
      footer: false,
      summary: false,
    },
    localTableAttrs: {},

    typeOptions: [{ text: 'Scroll', value: 'scroll' },{ text: 'Page', value: 'page' }],
    pageOptions: [{ text: 'Number', value: 'number' },{ text: 'Arrow', value: 'arrow' }],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),

    layoutApply: {
      get() { return this.localTableAttrs.layout.applied },
      set(val) { this.localTableAttrs.layout.applied = val; this.updateAttr() }
    },
    layoutBackColor: {
      get() { return this.localTableAttrs.layout.bColor },
      set(val) { this.localTableAttrs.layout.bColor = val; this.updateAttr() }
    },
    layoutMargin: {
      get() { return this.localTableAttrs.layout.margin },
      set(val) { this.localTableAttrs.layout.margin = val; this.updateAttr() }
    },
    borderApply: {
      get() { return this.localTableAttrs.border.applied },
      set(val) { this.localTableAttrs.border.applied = val; this.updateAttr(); }
    },
    borderTopWeight: {
      get() { return this.localTableAttrs.border.top.width },
      set(val) { this.localTableAttrs.border.top.width = val; this.updateAttr(); }
    },
    borderTopColor: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.top.color).hex },
      set(val) { 
        if(val == 'transparent') {
          this.borderTopWeight = 0
          this.borderTopOpacity = 0
          return
        }
        this.localTableAttrs.border.top.color = __F.hex2rgba(val, this.borderTopOpacity)
        this.updateAttr()
      }
    },
    borderTopOpacity: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.top.color).alpha },
      set(val) { this.localTableAttrs.border.top.color = __F.hex2rgba(this.borderTopColor, val); this.updateAttr(); }
    },
    borderRightWeight: {
      get() { return this.localTableAttrs.border.right.width },
      set(val) { this.localTableAttrs.border.right.width = val; this.updateAttr(); }
    },
    borderRightColor: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.right.color).hex },
      set(val) { 
        if(val == 'transparent') {
          this.borderRightWeight = 0
          this.borderRightOpacity = 0
          return
        }
        this.localTableAttrs.border.right.color = __F.hex2rgba(val, this.borderRightOpacity)
        this.updateAttr()
      }
    },
    borderRightOpacity: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.right.color).alpha },
      set(val) { this.localTableAttrs.border.right.color = __F.hex2rgba(this.borderRightColor, val); this.updateAttr(); }
    },
    borderBottomWeight: {
      get() { return this.localTableAttrs.border.bottom.width },
      set(val) { this.localTableAttrs.border.bottom.width = val; this.updateAttr(); }
    },
    borderBottomColor: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.bottom.color).hex },
      set(val) { 
        if(val == 'transparent') {
          this.borderBottomWeight = 0
          this.borderBottomOpacity = 0
          return
        }
        this.localTableAttrs.border.bottom.color = __F.hex2rgba(val, this.borderBottomOpacity)
        this.updateAttr()
      }
    },
    borderBottomOpacity: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.bottom.color).alpha },
      set(val) { this.localTableAttrs.border.bottom.color = __F.hex2rgba(this.borderBottomColor, val); this.updateAttr(); }
    },
    borderLeftWeight: {
      get() { return this.localTableAttrs.border.left.width },
      set(val) { this.localTableAttrs.border.left.width = val; this.updateAttr(); }
    },
    borderLeftColor: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.left.color).hex },
      set(val) { 
        if(val == 'transparent') {
          this.borderLeftWeight = 0
          this.borderLeftOpacity = 0
          return
        }
        this.localTableAttrs.border.left.color = __F.hex2rgba(val, this.borderLeftOpacity)
        this.updateAttr()
      }
    },
    borderLeftOpacity: {
      get() { return __F.rgba2hex(this.localTableAttrs.border.left.color).alpha },
      set(val) { this.localTableAttrs.border.left.color = __F.hex2rgba(this.borderLeftColor, val); this.updateAttr(); }
    },

    tablePagingEnabled: {
      get() { 
        if(this.localTableAttrs.pagination.enabled == undefined) {
          this.localTableAttrs.pagination.enabled = 'Y'
          this.updateAttr()
        }
        return this.localTableAttrs.pagination.enabled
      },
      set(val) { this.localTableAttrs.pagination.enabled = val; this.updateAttr(); }
    },
    tablePageType: {
      get() { return this.localTableAttrs.pagination.type },
      set(val) { this.localTableAttrs.pagination.type = val; this.updateAttr(); }
    },
    tablePageStyle: {
      get() { return this.localTableAttrs.pagination.style },
      set(val) { this.localTableAttrs.pagination.style = val; this.updateAttr(); }
    },
    tablePageNum: {
      get() { return this.localTableAttrs.pagination.showPageNo },
      set(val) { this.localTableAttrs.pagination.showPageNo = val; this.updateAttr(); }
    },
    tablePageRowNum: {
      get() { return this.localTableAttrs.pagination.rowsPerPage },
      set(val) { this.localTableAttrs.pagination.rowsPerPage = val; this.updateAttr(); }
    },


    infoAvailable() { return this.tableInfoBoxDisplay == 'Y' },

    tableInfoBoxDisplay: {
      get() { return this.localTableAttrs.showInfoBox },
      set(val) { this.localTableAttrs.showInfoBox = val; this.updateAttr(); }
    },
    tableTitleDisplay: {
      get() { return this.localTableAttrs.showTitle },
      set(val) { this.localTableAttrs.showTitle = val; this.updateAttr(); }
    },
    tableLegendDisplay: {
      get() { return this.localTableAttrs.showLegend },
      set(val) { this.localTableAttrs.showLegend = val; this.updateAttr(); }
    },
    tableSearchDisplay: {
      get() { return this.localTableAttrs.showSearchInput },
      set(val) { this.localTableAttrs.showSearchInput = val; this.updateAttr(); }
    },
    tableExcelDisplay: {
      get() { return this.localTableAttrs.showExcelExport },
      set(val) { this.localTableAttrs.showExcelExport = val; this.updateAttr(); }
    },

    actionButtonActivate: {
      get() { return this.localTableAttrs.actionButton.activate },
      set(val) { this.localTableAttrs.actionButton.activate = val; this.updateAttr(); }
    },
    actionButtonLabel: {
      get() { return this.localTableAttrs.actionButton.label },
      set(val) { this.localTableAttrs.actionButton.label = val; this.updateAttr(); }
    },
    actionButtonComponent: {
      get() { return this.localTableAttrs.actionButton.component },
      set(val) { this.localTableAttrs.actionButton.component = val; this.updateAttr(); }
    },
    
    tableExcelLimited: {
      get() { return this.localTableAttrs.excelExportLimit },
      set(val) { this.localTableAttrs.excelExportLimit = val; this.updateAttr(); }
    },
  },
  watch: {
    'datagridItem.tableAttrs': {
      handler(val) {
        if(!val || JSON.stringify(val) == JSON.stringify(this.localTableAttrs)) return

        // if(Object.keys(val).length === 0) this.localTableAttrs = _Dataform.table
        // else this.localTableAttrs = JSON.parse(JSON.stringify(val))

        // Temporal to... ------------------------ ###
        let tableAttrs_ = JSON.parse(JSON.stringify(val))
        let tableAttrKeys_ = Object.keys(tableAttrs_)

        if(tableAttrKeys_.length === 0) this.localTableAttrs = _Dataform.table
        else {
          let dataformKeys_ = Object.keys(_Dataform.table)
          tableAttrKeys_.forEach(k => { if(dataformKeys_.indexOf(k) < 0) delete tableAttrs_[k] })
          dataformKeys_.forEach(k => { if(tableAttrKeys_.indexOf(k) < 0) tableAttrs_[k] = _Dataform.table[k] })
          this.localTableAttrs = tableAttrs_
        }
        // --------------------------------------- ###
        
        this.updateAttr()
      },
      deep: true
    }
  },
  created() {
    // for preventing the occurrence of the error at the earlier initialization.
    this.localTableAttrs = _Dataform.table
    this.updateAttr()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, [
      'setDatagridItem'
    ]),

    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el) {
      if(el) this.localTableAttrs[el][i] = val
      this.setDatagridItem({ tableAttrs: JSON.parse(JSON.stringify(this.localTableAttrs)) })
    }
  }
}
</script>
